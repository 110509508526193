import { useEffect } from "react"
import { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Api } from "../api/api"
import { AuthContext } from "../providers/auth"

function ViewLogin() {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const context = useContext(AuthContext)
	const navigate = useNavigate()

	useEffect(() => {
		fetch("https://api.babacoiffure.com/").then(res => {
			console.log("res", res)
		})
	}, [])

	function handle() {
		Api.AdminLogin(email, password).then(() => {
			context.setConnected(true)
			navigate("/home")
		})
	}

	return (
		<div className="flex h-screen items-center justify-center ">
			<div className="rounded bg-black p-8 shadow-lg ">
				<h1 className="mb-4 text-2xl text-white">Connection</h1>
				<input
					type="text"
					placeholder="Email"
					className="mb-4 w-full rounded border p-2"
					value={email}
					onChange={e => setEmail(e.target.value)}
				/>
				<input
					type="password"
					placeholder="Mot de passe"
					className="mb-4 w-full rounded border p-2"
					value={password}
					onChange={e => setPassword(e.target.value)}
				/>
				<button
					className="rounded bg-white px-4 py-2 font-bold text-black"
					onClick={handle}
				>
					Connection
				</button>
			</div>
		</div>
	)
}

export default ViewLogin
