import { useContext } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../providers/auth"
import { MyCookies } from "../utils/cookie"

function NavBar() {
	const context = useContext(AuthContext)
	return (
		<nav className="flex h-screen w-1/6 flex-col gap-20 bg-black pl-10 pt-10 text-white">
			<div className="flex h-screen  flex-col gap-20 bg-black text-white">
				<h1 className="font-bold">Navigation</h1>
				<div className="flex flex-col gap-10">
					<Link to={"/pro"}>professionnel</Link>
					<Link to={"/client"}>client</Link>
				</div>
			</div>
			<div
				onClick={() => {
					MyCookies.Token.Remove()
					context.setConnected(false)
				}}
				className="my-10 hover:cursor-pointer"
			>
				Deconnexion
			</div>
		</nav>
	)
}

export default NavBar
