import Cookies from "js-cookie"

export namespace MyCookies {
	export namespace Token {
		export function Set(token: string, ExpiresAt: Date) {
			const expirationDate = new Date(ExpiresAt)
			Cookies.set("token", token, { expires: expirationDate })
		}
		export function Remove() {
			Cookies.remove("token")
		}
		export function Get(): string {
			const token = Cookies.get("token")
			if (typeof token === "string") return token
			else return ""
		}
	}
}
