import { useEffect, useState } from "react"
import { Api } from "../api/api"
import { User } from "../type/user"
import Portal from "../components/Portal"

function ViewUser() {
	const [user, setuser] = useState<User[]>()
	const [data, setData] = useState<{
		user_type: string
		email: string
		password: string
	}>({
		user_type: "",
		email: "",
		password: "",
	})

	function CreatePro() {
		Api.CreateProffessional(data.user_type, data.email, data.password)
	}

	useEffect(() => {
		Api.GetAllUser().then(res => setuser(res))
	}, [])

	return (
		<div className="flex w-full flex-col items-center">
			<div className="m-10 flex flex-row gap-5">
				<input
					placeholder="Nom d'utilisateur"
					className="box-border rounded-md border-2"
				/>
				<button>Rechercher</button>
			</div>
			<div className="align-start flex w-full flex-col">
				<p className="font-bold underline underline-offset-1">
					Utilisateurs
				</p>
				<Portal
					customButton={
						<div className="inline-block rounded bg-black px-4 py-2 font-bold text-white transition duration-300 hover:bg-gray-500">
							{" "}
							Créer un utilisateur{" "}
						</div>
					}
					children={
						<div className="flex w-full items-center">
							<input
								className="border-2"
								type="email"
								onChange={e =>
									setData({ ...data, email: e.target.value })
								}
							/>
							<input
								className=" border-2"
								type="password"
								onChange={e =>
									setData({
										...data,
										password: e.target.value,
									})
								}
							/>
							<button onClick={CreatePro}>Creer</button>
						</div>
					}
				/>
				<ul>
					{user?.map((data, index) => (
						<div
							className="my-2 flex w-full flex-row justify-between border-b border-gray-300 align-middle"
							key={index}
						>
							<li>{data.model_user_info?.email}</li>
						</div>
					))}
				</ul>
			</div>
		</div>
	)
}

export default ViewUser
