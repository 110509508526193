const prefix_preprod = "https://api.babacoiffure.com/admin"

export const ENV = {
	API_ENDPOINT: "http://localhost:3001/admin",
}

switch (process.env.REACT_APP_ENV) {
	case "PROD":
		break
	case "PREPROD":
		ENV.API_ENDPOINT = prefix_preprod
		break
	case "DEV":
		break
}
