import React, { createContext, FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { MyCookies } from "../utils/cookie"

interface AuthContextProps {
	connected: boolean
	setConnected: React.Dispatch<React.SetStateAction<boolean>>
}

export const AuthContext = createContext<AuthContextProps>({
	connected: false,
	setConnected: () => {},
})

const ProviderAuth: FC<{ children: React.ReactNode }> = ({ children }) => {
	const [connected, setConnected] = useState<boolean>(false)
	const navigate = useNavigate()

	useEffect(() => {
		const token = MyCookies.Token.Get()
		if (token !== "") {
			setConnected(() => {
				return true
			})
		} else {
			navigate("/login")
		}
	}, [connected, navigate])

	return (
		<AuthContext.Provider value={{ connected, setConnected }}>
			{children}
		</AuthContext.Provider>
	)
}

export default ProviderAuth
