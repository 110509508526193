import { ENV } from "../config/api"
import { User } from "../type/user"
import { MyCookies } from "../utils/cookie"

export namespace Api {
	export async function GetAllUser() {
		const res = await fetch(ENV.API_ENDPOINT + "/pro")
		const user = (await res.json()) as User[]
		return user
	}

	export async function CreateProffessional(
		user_type: string,
		email: string,
		password: string,
	) {
		const body = {
			user_type: user_type,
			email: email,
			password: password,
		}

		const res = fetch(ENV.API_ENDPOINT + "/pro", {
			headers: {
				"Content-Type": "application/json",
			},
			method: "POST",
			body: JSON.stringify(body),
		})
		res.catch(err => {
			throw new Error(err)
		})
	}

	export async function AdminLogin(
		mail: string,
		password: string,
	): Promise<string> {
		const body = {
			mail: mail,
			password: password,
		}

		try {
			const res = await fetch(ENV.API_ENDPOINT + "/login", {
				headers: {
					"Content-Type": "application/json",
				},
				method: "POST",
				body: JSON.stringify(body),
			})

			if (!res.ok) {
				throw new Error("Error logging in")
			}

			const data = await res.json()
			MyCookies.Token.Set(data.Token, data.ExpiresAt)
			return MyCookies.Token.Get()
		} catch (err: any) {
			throw new Error(err.message)
		}
	}
}
