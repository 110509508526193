import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import Home from "./App"
import reportWebVitals from "./reportWebVitals"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import ViewUser from "./Views/users"
import Root from "./components/Root"
import ViewClient from "./Views/client"
import ViewLogin from "./Views/login"

const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		children: [
			{
				path: "/home",
				element: <Home />,
			},
			{
				path: "/pro",
				element: <ViewUser />,
			},
			{
				path: "/client",
				element: <ViewClient />,
			},
		],
	},
	{
		path: "/login",
		element: <ViewLogin />,
	},
])

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>,
)

reportWebVitals()
