function Home() {
	return (
		<div className="flex flex-row">
			<div className="w-full p-10">
				<div className="my-8">
					<h1 className="mb-4 text-4xl font-bold text-gray-800">
						Bienvenue sur Notre Plateforme!
					</h1>
					<p className="mb-6 text-lg text-gray-600">
						Des nouvelles fonctionnalites vont arriver au fur a
						mesure je te tiendrais au courant
					</p>
				</div>
			</div>
		</div>
	)
}

export default Home
