import { Outlet } from "react-router-dom"
import ProviderAuth from "../providers/auth"
import NavBar from "./NavBar"

function Root() {
	return (
		<ProviderAuth>
			<div className="flex flex-row">
				<NavBar />
				<div className="w-full p-10">
					<Outlet />
				</div>
			</div>
		</ProviderAuth>
	)
}

export default Root
