import React, { useState } from "react"
import { createPortal } from "react-dom"

const Portal: React.FC<{
	children: React.ReactNode
	customButton: React.ReactNode
}> = ({ children, customButton }) => {
	const [showModal, setShowModal] = useState(false)
	return (
		<>
			<button onClick={() => setShowModal(true)}>{customButton}</button>
			{showModal &&
				createPortal(
					<ModalContent
						onClose={() => setShowModal(false)}
						children={children}
					/>,
					document.body,
				)}
		</>
	)
}

const ModalContent: React.FC<{
	onClose: () => void
	children: React.ReactNode
}> = ({ onClose, children }) => {
	return (
		<div className="justify-space-evenly absolute bottom-16 left-1/2 top-16 flex h-1/2 w-4/5 -translate-x-1/2 transform items-center rounded-lg border-2 border-gray-300 bg-white shadow-lg">
			{children}
			<button onClick={onClose}>Fermer</button>
		</div>
	)
}

export default Portal
